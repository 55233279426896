import * as React from "react";
import { Container, Row, Col } from 'react-bootstrap';

import MemberDetail from "../../components/parts/member-detail";

import "../../styles/global/base.scss";
import "../../styles/global/_list.scss";
import "../../styles/member-detail.scss";

const primaryWorks = [];

const ProfileContent = () => {
  return (
    <div className="detail">
      <p>
        1980年広島県生まれ。東京理科大学理工学部建築学科を卒業後、グラフィックデザイナーを経て2016年より株式会社モンタージュ入社。国内外の大型イベントにおいて展示映像、空間演出を中心としたプロデュースを行う。<br />
        ドバイ国際博覧会日本館ではコンテンツプロデューサーとして参加。
      </p>
      <ul className="links">
        <li>
          <a href="https://twitter.com/Shungo_1029" target="_blank" rel="noreferrer">Twitter ↗</a>
        </li>
        <li>
          <a href="https://www.instagram.com/ootashiyungo/" target="_blank" rel="noreferrer">Instagram ↗</a>
        </li>
        <li>
          <a href="https://www.facebook.com/profile.php?id=100012185171339" target="_blank" rel="noreferrer">Facebook ↗</a>
        </li>
      </ul>
    </div>
  );
};

const AwardsContent = () => {
  return (
    <Container fluid className="memberAwards">
      <Row>
        <Col>
          <h3 className="titleTopBorder">Awards</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row className="awardsTitles">
            <Col xs={6} lg={6} className="d-none d-lg-block titleLabel">Works</Col>
            <Col xs={6} lg={6} className="d-none d-lg-block titleLabel">Award title</Col>
          </Row>
          <Row className="awardsCatBlock">
            <Col xs={6} lg={6} className="col">2020年ドバイ国際博覧会 日本館コンテンツプロデュース</Col>
            <Col xs={6} lg={6} className="col">BIE Prizes and Awards<br />Exhibition Design Gold Award</Col>
          </Row>
          <Row className="awardsCatBlock">
            <Col xs={6} lg={6} className="col">Milano Salone 2018「Air Inventions」 プロデュース</Col>
            <Col xs={6} lg={6} className="col">Milano Design Award 2018 ベストテクノロジー賞</Col>
          </Row>
          <Row className="awardsCatBlock">
            <Col xs={6} lg={6} className="col">Milano Salone 2017「Electronics Meets Crafts:」 プロデュース</Col>
            <Col xs={6} lg={6} className="col">Milano Design Award 2017 ベストストーリーテリング賞<br />映文連アワード2017 経済産業大臣賞</Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

const MemberDetailPage = ({ location }) => {
  return (
    <MemberDetail
      location={location}
      primaryWorks={primaryWorks}
      profileContent={ProfileContent}
      awardsContent={AwardsContent}
    />
  );
};

export default MemberDetailPage;
